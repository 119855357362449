import { createRoot, Suspense } from '@wordpress/element';
import Block from './Block';
import { parseAttributeBooleans } from '../utils';

window.addEventListener( 'DOMContentLoaded', () => {
    const element = document.querySelector(
        '.wp-block-goland-features-goland-blocks-features-properties'
    );
    if ( element ) {
        let attributes = { ...element.dataset/*, ...goland*/ };
        attributes = parseAttributeBooleans(attributes);
        const root = createRoot(element);

        root.render(
            <Suspense fallback={ <div className="wp-block-placeholder" /> }>
                <Block { ...attributes } />
            </Suspense>
        );
    }
} );
